<template>
	<div class="page-web page_regi">
		<RegiHead :MyLocal="location" @newLocal="location=$event" />
		<div class="regi-bd change_bd">
			<div class="regi_name">{{$t('acTit')}}</div>
			<el-form ref="form" class="regi-from" :model="form">
				<el-form-item prop="phone" :rules="[{ required: true, message: $t('chtip9'), trigger: 'blur' }]">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('acTxt4')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<div class="regi_phone flex flex-ac flex-hc">
								<div class="regi_unit">+</div>
								<el-select v-model="form.country" placeholder="" popper-class="select-drop">
									<el-option v-for="(item,index) in country" :key="index" :label="item.areaCode"
										:value="item.zhName">
										<div class="select-item" @click="countryItem(item.countryImageUrl)">
											<div class="select-item__bd flex_bd">
                        <span>{{location == 'zh_CN' ? item.zhName : item.enName}}</span>
												<span>+{{item.areaCode }}</span>
											</div>
										</div>
									</el-option>
								</el-select>
							</div>
							<el-input v-model="form.phone" placeholder=""></el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="password" :rules="[{ required: true, message: $t('chtip10'), trigger: 'blur' }]">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('acTxt5')}}</div>
						<div class="regi_gr_b">
							<el-input v-model="form.password" type="password" placeholder="">
							</el-input>
						</div>
					</div>
				</el-form-item>
				<el-form-item prop="code" :rules="[{ required: true, message: $t('chTxt3'), trigger: 'blur' }]">
					<div class="regi_group">
						<div class="regi_gr_t">{{$t('chTxt12')}}</div>
						<div class="regi_gr_b flex flex-ac">
							<el-input v-model="form.code" placeholder=""></el-input>
							<el-button class="btn btn_yzm" type="primary" @click="sendCode" :disabled="disabled">
								<span v-if="disabled">{{count}} S</span>
								<span v-if="!disabled">{{$t('code2')}}</span>
							</el-button>
						</div>
					</div>
				</el-form-item>
				<el-form-item>
					<div class="regi_group chan_btn flex flex-ac flex-between">
						<el-button class="btn btn_reset" type="primary" @click="resetForm('form')">{{$t('reset')}}
						</el-button>
						<el-button class="btn" type="primary" @click="submitForm('form')">{{$t('save')}}</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div class="regi_tips">Copyright ©2024 Alcex All rights reserved.</div>
		</div>
	</div>
</template>

<script>
	import {
		getCountry,
		bindPhone,
		bindMobileCode
	} from '@/api/api/user'
	// @ is an alias to /src
	import RegiHead from '@/components/RegiHead.vue'
	export default {
		components: {
			RegiHead,
		},
		data() {
			return {
				form: {
					country: '美国',
				},
				location: "en_US",
				country: [],
				count: 60,
				disabled: false,
			};
		},
		created() {
			this.location = localStorage.getItem('lang')
		},
		mounted() {
			this.countryList()
		},
		methods: {
			countryList() {
				getCountry().then(res => {
					if (res.code == 0) {
						this.country = res.data
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						bindPhone({
							code: this.form.code,
							password: this.form.password,
							phone: this.form.phone
						}).then(res => {
							if (res.code == 0) {
								this.$message.success(this.$t('save_success'));
								this.$router.push('/admin')
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			sendCode() {
				bindMobileCode({
					country: this.form.country,
					phone: this.form.phone
				}).then(res => {
					if (res.code == 0) {
						let waitTime = 60;
						this.disabled = true
						let clock = window.setInterval(() => {
							this.count = waitTime
							waitTime--;
							if (waitTime <= 0) {
								waitTime = 60;
								this.disabled = false
								window.clearInterval(clock);
							}
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				})

			}
		}
	}
</script>
